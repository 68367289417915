import React from "react";
import Vector from "assets/Vector.svg";
export const Information = ({
  title,
  ThemeColor,
  orgDetails,
  setOrganizationModalOpen,
  orgTypes,
}) => {
  const orgTypeName = orgTypes.filter((item) => {
    if (orgDetails?.organization_type === item.id) {
      return item;
    }
  });

  const Details = {
    Organization_Name: orgDetails?.name,
    Organization_Long_Name: orgDetails?.longName,
    Organization_Type: orgTypeName[0]?.name,
    Organization_Website: orgDetails?.website,
    Organization_Domain: orgDetails?.domain,
    Organization_email: orgDetails?.email,
  };

  const transformValues = (item) => {
    let letter = "";
    letter = item?.replace(/[_]+/g, " ");
    return letter;
  };

  return (
    <div
      className={`card_p rounded ${
        ThemeColor
          ? "bg-white card-board text-black"
          : "dashboard_card_bg_color border text-white"
      }`}
    >
      <div className="information-header flex justify-between items-center bg-for-light px-12 py-2">
        <div className="text-base font-semibold">{title}</div>
      </div>
      <div className="flex justify-end px-12 pt-6 font-medium">
        <div className="flex  action_button cursor-pointer hover:text-blue-500  px-3 py-1 rounded">
            <div
              className="flex items-center"
              onClick={() => {
                setOrganizationModalOpen(true);
              }}
            >
              <img
                src={Vector}
                alt="import_icon"
                className="flex px-1 mt-0.5 static"
              />
              Edit
            </div>
          </div>
        
      </div>
      <div className=" flex justify-between pt-4 pb-8 flex-wrap px-12 org-details  ">
        {Object.keys(Details).map((item, i) => (
          <div className="w-4/12">
            <p className="text-black font-semibold bg-for-light boarder-description px-6 py-2">
              {transformValues(item)} *
            </p>
            <p className="item-detail px-6 py-2">{Details[item]}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
