import React from "react";
import PropTypes from "prop-types";

export const ErrorMessage = ({ message }) => {
  return <div className="pt-1 text-red-500 text-sm">{message}</div>;
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};
