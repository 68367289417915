import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { Select } from "../Select";
import sun_full from "../../assets/sun_full.png";
import { getCookie } from "services/session/cookies";
import { useTranslation } from "react-i18next";

export const UserGreetings = ({ title, description, className, icon }) => {
  const [language, setLanguage] = useState("");
  const [t, i18n] = useTranslation("global");
  const [user, setUser] = useState('')
  const [currentTime, setCurrentTime] = useState(moment().format("LT"))
  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const onchangeHandler = (value) => {
    setLanguage(value);
    i18n.changeLanguage(value);
  };
  let curDate = new Date();
  curDate = curDate.getHours();
  let greetings = "";
  if (curDate >= 1 && curDate < 12) {
    greetings = "grettings.goodMorning";
  } else if (curDate >= 12 && curDate < 19) {
    greetings = "grettings.goodAfternoon";
  } else {
    greetings = "grettings.goodEvening";
  }

  useEffect(() => {
    try {
      const user = JSON.parse(getCookie("user" ? "user" : {}));
      setUser(user);
    } catch (err) {
      console.log("error", err);
    }
  }, []);

  const myFunction = () => {
    let currentTime = moment().format("LT");
    setCurrentTime(currentTime);
  };

  useEffect(() => {
    const intervalId = setInterval(myFunction, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="greetings flex justify-between text-white pl-6 ">
      <div>
        <p className="text-2xl">
          {t(`${greetings}`)}! {user?.userName ? user?.userName : "User Name"}
        </p>
        <div className="paragraphs_font pt-1 font-thin">
          {moment().format("dddd , DD MMM YYYY")}
        </div>
      </div>
      <div>
        <div className="flex items-center">
          <span className="pr-2 flex items-center">
            <img src={sun_full} alt="sunny" className="pr-1" /> 34
          </span>
          {currentTime}
        </div>
        
      </div>
    </div>
  );
};
