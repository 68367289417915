import React from "react";

export const UserManagemntCard = ({
  title,
  Count,
  className,
  icon,
  ThemeColor,
}) => {
  return (
    <div
      className={`${
        ThemeColor
          ? "bg-white text-black card-board"
          : "dashboard_card_bg_color border"
      } w-1/5 py-8 px-4 card_p z-0 ${className}`}
    >
      <div className="dashboard_card_inner_right text-color">
        <div className="text-normal paragraphs_font">{title}</div>
        <div
          className={`${
            ThemeColor ? " text-black " : " text-white "
          } flex items-center text-5xl font-normal`}
        >
          {Count}
          {icon && (
            <div className="dashboard_card_left w-1/6 pl-2 mt-4">
              <img src={icon} alt="icon" className="w-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
