import React from "react";
import PropTypes from "prop-types";
import BG_Login from "../../assets/BG_Login.png";

export const LoginLayout = ({ children }) => {
  return (
    <div className="relative w-full flex justify-center">
      <div className="absolute top-0 right-0 w-full">
        <img
          src={BG_Login}
          alt="login_background"
          className="fixed top-0 animated max-w-full bg_img_height right-position"
        />
      </div>
      <div className="fixed h-screen w-full bg-black text-white opacity-50"></div>
      <div className="flex justify-center pl-4 z-50 w-full">
        {children}
      </div>
    </div>
  );
};

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
