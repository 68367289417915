import React, { useState } from 'react';

export const Stepper = ({tab_buttons, setTabcount, tabcount, setPageNO}) => {
  return (
    <div className="user_register py-2">
      <div className="container card_p user_registration_inner">
        <div className="tabs_left">
          {tab_buttons.map((tab_items, i) => (
            <>
            <div
              className={`flex items-center py-1 ${
                tab_items.id === tabcount ? "active" : ""
              }`}
              // onClick={() => {
              //   if(tab_items.id === 1){
              //     setPageNO(1)
              //   }
              //   setTabcount(tab_items.id)
              // }
              // }
              id={tab_items.id}
              key={tab_items.id}
            >
             <div className='text-5xl paragraphs_font w-14 py-2'>{i + 1}.&nbsp;</div><div className={`text-3xl paragraphs_font ${
                tab_items.id === tabcount ? "gradient_text leading-normal" : ""
              }`}> { tab_items?.name}</div>
            </div>
            {tab_items.border ? 
            <div className="left_bordered"></div>
           : ""}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
