import logo from "../../assets/logo.png";

export const LeftPannel = ({
  createNewAccount,
  headingtext,
  coloredText,
  paragraph,
  lowerText,
  linkText
}) => {
  return (
    <>
      <div className={`${lowerText ? "py-12 pl-8 pr-2":"py-12 pl-8 pr-2"} Login_left_pannel h-full`}>
        <div className="h-19 flex w-72 pt-5 pb-4">
          <img src={logo} alt="logo" className="w-full max-w-64 h-auto" />
        </div>
        <div className="pt-3 pb-1">
          {createNewAccount ? (
            <div className="flex items-center">
              <h1 className="text-4xl leading-relaxed pr-2">{headingtext}</h1>
              <h1 className="text-4xl gradient_text">{coloredText}</h1>
            </div>
          ) : (
            <>
              <h1 className="text-4xl leading-snug">
                Beyond trends navigating the
              </h1>
              <h1 className="text-4xl gradient_text leading-snug">data driven revolution</h1>
            </>
          )}
          <p className="pt-2 pb-8 text-lg font-thin tracking-wide para_color paragraphs_font w-4/5">
            {paragraph
              ? paragraph
              : "Harnessing the power of data-driven insights to decode customer behaviors, predict trends and optimize strategies."}
          </p>
          <div className="flex content-between items-center">
            <div className="w-3/6 pt-6 pb-8 opacity_blue px-5 box-shadow-sign-in">
              <p className="text-2xl">BI & Analytics</p>
              <p className="pt-3 pb-4 text-xs text-white paragraphs_font font-thin leading-snug">
                Use intuitive dashboards, reporting, and Al-generated insights
                to see what's going on in your business and make the next right
                move.
              </p>
              <button className="button_gold px-10 py-2 text-sm paragraphs_font">
                See BI & Analytics
              </button>
            </div>
            <div className="w-3/6 px-4">
              <h1 className="text-xl paragraphs_font">The Spreadsheet</h1>
              <p className="text-base font-thin para_color paragraphs_font">
                an interface you already know
              </p>
              <h1 className="text-xl pt-2 paragraphs_font">Powerful Operations</h1>
              <p className="text-base font-thin para_color paragraphs_font">
                on billions of rows in seconds
              </p>
              <h1 className="text-xl pt-2 paragraphs_font">Collaborate</h1>
              <p className="text-base font-thin para_color paragraphs_font">
                with your team on telling
              </p>
              <p className="text-base font-thin para_color paragraphs_font">your data story</p>
            </div>
          </div>
          <div className={`${lowerText ? "pt-10 para_color paragraphs_font text-base w-3/5 leading-relaxed":""}`}>{lowerText ? lowerText : ""} <span className="link-text">{linkText? linkText :""}</span></div>
        </div>
      </div>
    </>
  );
};
