import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import home from "assets/home.png";
import group from "assets/group.png";
import profit from "assets/profit.png";
import Settings from "assets/Settings.png";
import location from "assets/location.png";
import hamburger from "assets/hamburger.png";
import profit_color from "assets/profit_color.png";
import group_colored from "assets/group_colored.png";
import home_unfilled from "assets/home_unfilled.png";
import pop_management from "assets/pop_management.png";
import Group_management from "assets/Group_management.png";
import location_colored from "assets/location_colored.png";
import { MainMenu } from "utils/constants/SibarBarMenu";
import { setCookie } from "services/session/cookies";

const Sidebar = ({ ThemeColor, setSideNavigationShow, sideNavigationShow }) => {
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState();

  const handleEvent = (key) => {
    setIsShown(Number(key));
    const selectedItem = MainMenu.find((item) => item.key === key);
    if (selectedItem) {
      const { label, link, items } = selectedItem;
      const mappedItems = items
        ? items.map((item) => ({ label: item.label, value: item.link }))
        : [];
      let result = [{ label, value: link }];
      if (mappedItems.length > 0) {
        result = result.concat(mappedItems);
      }
      setCookie("navItems", JSON.stringify(result));
    }
  };
  return (
    <>
      <div
        className={`text-white h-dvh w-20 fixed top-0 bottom-0 pt-4 flex flex-col items-center justify-between z-30 bg-black`}
      >
        <div className="flex flex-col items-center justify-between">
          <div
            className="pt-3 cursor-pointer"
            onClick={() => {
              setSideNavigationShow(!sideNavigationShow);
            }}
          >
            <img src={hamburger} alt="logo_icon" />
          </div>
          <div className="pt-14" onClick={() => handleEvent("A01", "1")}>
            <Link to="/main-dashboard">
              <img
                src={isShown === 1 ? home : home_unfilled}
                alt="home_icon"
                className="pt-5 cursor-pointer"
              />
            </Link>
          </div>
          <div className="pt-8" onClick={() => handleEvent("B02", "2")}>
            <Link to="/activity-management">
              <img
                src={isShown === 2 ? profit_color : profit}
                alt="activity_icon"
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="pt-8" onClick={() => handleEvent("C03", "3")}>
            <Link to="/user-management">
              <img
                src={isShown === 3 ? group_colored : group}
                alt="home_icon"
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="pt-8" onClick={() => handleEvent("D04", "4")}>
            <Link to="/organization-management">
              <img
                src={Group_management}
                alt="home_icon"
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="pt-8" onClick={() => handleEvent("E05", "5")}>
            <Link to="/pop-type-management">
              <img
                src={pop_management}
                alt="home_icon"
                className="cursor-pointer"
              />
            </Link>
          </div>
          <div className="pt-8" onClick={() => handleEvent("F01", "6")}>
            <Link to="/geo-fence">
              <img
                src={isShown === 6 ? location_colored : location}
                alt="home_icon"
                className="cursor-pointer"
              />
            </Link>
          </div>
        </div>
        <div className="pb-8 flex items-center" onClick={() => setIsShown(7)}>
          <img src={Settings} alt="Settings_logo" className="cursor-pointer" />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
