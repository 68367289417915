import React from "react";
import PropTypes from "prop-types";

export const TextArea = ({
  className = "form-control",
  type = "text",
  name = "",
  id="",
  disabled = false,
  value = "",
  label = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  placeholder,
  rows,
  cols,
}) => {
  return (
    <>
      <textarea
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={changeHandler}
        onBlur={blurHandler}
        value={value}
        disabled={disabled}
        required={required}
        cols={cols}
        rows={rows}
      />
    </>
  );
};
TextArea.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};
