import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function AuthReducer(state = StoreState.auth, action) {
  switch (action.type) {
    case ActionType.LOGIN:
      var s = cloneDeep(state);
      s.user = action.data;
      return s;
    case ActionType.GET_PERMISSION:
      var s1 = cloneDeep(state);
      s1.permissions = JSON.stringify(action.data);
      return s1;
    case ActionType.USERTYPES:
      var s1 = cloneDeep(state);
      s1.userTypes = action.data;
      return s1;
    default:
      return state;
  }
}
