import PropTypes from "prop-types";
import { Select } from "antd";
// import { InputLabel, ErrorMessage } from 'components';
// import 'antd/dist/antd.css';
const { Option } = Select;

export const MultiSelect = ({
  value = [],
  name = "",
  label = "",
  disabled = false,
  changeHandler,
  errorMessage,
  options = [],
  className,
  required = false,
  placeholder
}) => {
  const formatValue = (value) => {
    if (value && typeof value === "string") {
      return value.split(",");
    }
    return value;
  };
  return (
    <div className="multi_select_field">
      {/* {label ? (
        <InputLabel labelFor={name} labelText={label} required={required} />
      ) : (
        ''
      )} */}
      <Select
        mode="multiple"
        disabled={disabled}
        allowClear
        className={`${
          className
            ? className
            : "w-full field_bg-blue text-white outline-none border"
        }`}
        style={{
          width: "100%",
        }}
        status={errorMessage ? "error" : ""}
        placeholder={placeholder}
        value={value === "" ? [] : formatValue(value)}
        onChange={(selectedValues) => changeHandler(selectedValues, name)}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {options.map((item, index) => {
          return (
            <Option key={index} value={item.value || item.id}>
              {item.label ||
                item.name ||
                `${item.first_name} ${item.last_name}`}
            </Option>
          );
        })}
      </Select>
      {/* {errorMessage ? (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      ) : (
        ""
      )} */}
    </div>
  );
};
MultiSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  changeHandler: PropTypes.func,
  options: PropTypes.array.isRequired,
};
