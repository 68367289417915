import { LOGIN, GET_PERMISSION, USERTYPES } from "./Types";

export function LoginAction(data) {
  return {
    type: LOGIN,
    data,
  };
}

export function UserTypesAction(data) {
  return {
    type: USERTYPES,
    data,
  };
}

