import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";
import { setCookie } from "services/session/cookies";

export const errorMessages = (message) => {
  if (typeof message === "string") {
    return message;
  } else {
    let list = "";
    for (var i = 0; i < message?.length; i++)
      list += ` ${i + 1}- ${message[i]} `;
    return list;
  }
};

export const successMessage = (message) => {
  return toast.success(message);
};

export const logOut = () => {
  setCookie("user", "");
  setCookie("accessToken", "");
};

export const mapValues = (isEdit, data, currentRecord, dateType) => {
  const fields = cloneDeep(data);
  let editData = cloneDeep(currentRecord);
  if (isEdit && fields.length && editData && Object.keys(editData).length) {
    fields.map((item) => {
      const fieldName = item["xmlName"];
      if (item["fieldType"] === "select") {
        item["value"] = editData[fieldName];
      } else if (item["fieldType"] === "date") {
        if (dateType && dateType === "momentType") {
          item["value"] = editData[fieldName]
            ? moment(editData[fieldName], "DD-MM-YYYY").toDate()
            : "";
        } else {
          item["value"] = editData[fieldName]
            ? new Date(editData[fieldName])
            : "";
        }
      } else {
        item["value"] =
          editData[fieldName] && editData[fieldName] != "null"
            ? editData[fieldName]
            : "";
      }
    });
  }
  return fields;
};

export const mapAllValues = (isEdit, state, data) => {
  console.log("isEdit, state, data", isEdit, state, data);
};

export const registerRegex = {
  userName: "^[a-zA-Z ]+$",
  email: "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
  mobileNumber: /^[0-9]{4}-[0-9]{7}$/,
  phoneNumber: /^[0-9]{3}-[0-9]{7}$/,
  password: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  confirmPassword:
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
  name: "^[a-zA-Z ]+$",
  firstName: "^[a-zA-Z ]+$",
  lastName: "^[a-zA-Z ]+$",
  longName: "^[a-zA-Z ]+$",
  // website: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  // domain: "(https?:)?([w-])+.{1}([a-zA-Z]{2,63})([/w-]*)",
  ntnNumber: /^[0-9]{7}-[0-9]{1}$/,
  // address: "^\\W*(\\w+(\\W+|$)){1,500}$",
  cnic: /^[0-9]{5}-[0-9]{7}-[0-9]$/,
  idenityId: /^[0-9]{5}-[0-9]{7}-[0-9]$/,
  contact: /^[0-9]{4}-[0-9]{7}$/,
};

export const registerErrorMessageObj = {
  userName: "Please enter valid name",
  firstName: "Please enter valid name",
  lastName: "Please enter valid name",
  email: "Please enter valid email e.g@.com",
  mobileNumber: "Please enter valid mobile number 0XXX-XXXXXXX",
  phoneNumber: "Please enter valid phone number 0XX-XXXXXXX",
  password:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  confirmPassword:
    "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:",
  name: "Please enter valid name",
  longName: "Please enter valid long name  ",
  cnic: "Please enter valid cnic XXXXX-XXXXXXX-X",
  idenityId: "Please enter valid cnic XXXXX-XXXXXXX-X",
  contact: "Please enter valid mobile number 0XXX-XXXXXXX",
  ntnNumber: "Please enter valid NTN Number xxxxxxx-x",
};

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
