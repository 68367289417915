export const validateForm = () => {
  var form = document.getElementsByClassName("needs-validation")[0];
  var elements = form.getElementsByClassName("field");
  let isValid = true;
  for (var i = 0; i < elements.length; i++) {
    if (!elements[i].checkValidity()) {
      elements[i].classList.add("was-validated");
      isValid = false;
    }
  }
  return isValid;
};

export const unCheckForm = () => {
  var form = document.getElementsByClassName("needs-validation")[0];
  var elements = form.getElementsByClassName("inputfield");
  for (var i = 0; i < elements.length; i++) {
    elements[i].classList.remove("was-validated");
  }
};

export const validateField = () => {
  var elements = document.getElementsByClassName("field");
  let isValid = true;
  for (var i = 0; i < elements.length; i++) {
    if (elements[i].checkValidity()) {
      elements[i].classList.remove("was-validated");
      isValid = false;
    }
  }
  return isValid;
};

export const customFormValidation = (data, errorMessages) => {
  var form = document.getElementById("myForm");
  var elements = form.getElementsByClassName("field");
  let isValid = true;
  for (var i = 0; i < elements.length; i++) {
    if (!elements[i].checkValidity()) {
      elements[i].classList.add("was-validated");
      isValid = false;
    }
  }
  return isValid;
};
