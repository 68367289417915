import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";

export const DateInput = ({
  className = "form-control",
  type = "text",
  name = "",
  id = "",
  disabled = false,
  value = "",
  label = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  placeholder,
  activity,
  labelClass,
  ThemeColor
}) => {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;
    return `${year}-${month}-${day}`;
  };

  return (
    <div className={`input_${type} input-group`}>
      {label !== "" ? (
        <div className="mb-1">
          <label
            className={`${
              activity
                ? "text-black text-xs paragraphs_font"
                : `text-xs paragraphs_font ${
                    labelClass
                      ? !ThemeColor
                        ? "text-white"
                        : "text-black"
                      : "text-white"
                  }`
            }`}
            htmlFor={name}
          >
            {label}{" "}
            {label !== "" && required ? (
              <span className="text-red-500 ml-1">*</span>
            ) : (
              ""
            )}
          </label>
        </div>
      ) : (
        ""
      )}
      <input
        type={type}
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={changeHandler}
        onBlur={blurHandler}
        value={value}
        disabled={disabled}
        required={required}
        autoComplete="off"
        max={getCurrentDate()}
      />
      {errorMessage ? (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

DateInput.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};
