import AuthReducer from "./AuthReducer";
import CommonReducer from "./CommonReducer";
import queriesReducer from './queries.reducer';
import UserManagementReducer from "./UserManagementReducer";
import POPManagementReducer from "./POPManagementReducer";
import CategoryManagementReducer from "./CategoriesMngReducer";
import ActivityManagementReducer from "./ActivityManagement";
import ApiCallStatusReducer from "./ApiCallStatusReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  auth: AuthReducer,
  common: CommonReducer,
  UserManagement: UserManagementReducer,
  POPManagemnt: POPManagementReducer,
  CategoryManagement:CategoryManagementReducer,
  activtyManagement : ActivityManagementReducer,
  ApiCallStatusReducer : ApiCallStatusReducer,
  Queries: queriesReducer,
});

export default rootReducer;
