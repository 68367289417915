var StoreState = {
  auth: {
    accessToken: {},
    refreshToken: "",
    user: [],
    permissions: {},
    userTypes: [],
  },
  common: {
    Theme: "",
    Language: "",
    city: [],
    geoFence: [],
  },

  apiCallStatus: {
    apiCallFor: "",
    statusCode: "",
    isLoading: false,
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
  userManagement: {
    getAllUsers: [],
    getAllUserStats: [],
    getAllOrganizations: [],
    getAllGroups: [],
    getAllPermissons: [],
    getAllPermissonsType: [],
    organizationByType: [],
    organizationDetails: [],
    locations: [],
    groupUsers: [],
    roleGroup: [],
    organizationTypes:[],
    sitiesData:[],
    sitieBranches:[],
    allUsersOfGroup:[],
    sitesData:[],
    branchesData:[],
    allPermissions:[],
  },
  popManagement: {
    getAllPOPTypes: [],
    getPOPTypes: [],
    getPOPRegions: [],
    getPOPZones: [],
    getPOPTerritories: [],
    popTypes: [],
    popTypeStats: {},
    location: [],
    organisation: [],
    regionStat: [],
    popBypopId: [],
  },

  categoryManagement: {
    getAllCategories: [],
    getAllSubCategories: [],
    getAllProducts: [],
  },

  activityManagement: {
    getAllPrograms: [],
    getProgramForms: [],
    activityFormData: [],
    activityForm: [],
  },
  apiCallStatus: {
    apiCallFor: "",
    statusCode: "",
    isLoading: false,
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
};
export default StoreState;
