import moment from "moment";

export const Columns = [
  {
    title: "Name",
    dataIndex: "first_name",
    key: "first_name",
    sorter: (a, b) =>
      a?.first_name?.toLowerCase() === b?.first_name?.toLowerCase(),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) =>
      a?.email?.toLowerCase()?.localeCompare(b.email?.toLowerCase()),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) =>
      a?.status?.toLowerCase()?.localeCompare(b.status?.toLowerCase()),
    key: "status",
  },
  {
    title: "Created",
    dataIndex: "created_date",
    key: "created_date",
    render: (row) => moment(row).format("DD-MM-YY"),
    sorter: (a, b) =>
      a?.created?.toLowerCase()?.localeCompare(b.created?.toLowerCase()),
  },
];

export const organizationColumns = [
  {
    title: "Organization Type",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.toLowerCase() === b?.name?.toLowerCase(),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) =>
      a?.status?.toLowerCase()?.localeCompare(b.status?.toLowerCase()),
    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (row) => moment(row).format("DD-MM-YY"),
    sorter: (a, b) =>
      a?.created?.toLowerCase()?.localeCompare(b.created?.toLowerCase()),
  },
];

export const rolesColumns = [
  {
    title: "Role Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.toLowerCase() === b?.name?.toLowerCase(),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    sorter: (a, b) =>
      a?.remarks?.toLowerCase()?.localeCompare(b.remarks?.toLowerCase()),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: (a, b) =>
      a?.status?.toLowerCase()?.localeCompare(b.status?.toLowerCase()),
    key: "status",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (row) => moment(row).format("DD-MM-YY"),
    sorter: (a, b) =>
      a?.created?.toLowerCase()?.localeCompare(b.created?.toLowerCase()),
  },
];

export const GroupColumns = [
  {
    title: "Group Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a?.name?.toLowerCase() === b?.name?.toLowerCase(),
  },
  {
    title: "Remarks",
    dataIndex: "remarks",
    key: "remarks",
    sorter: (a, b) =>
      a?.remarks?.toLowerCase()?.localeCompare(b.remarks?.toLowerCase()),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) =>
      a?.status?.toLowerCase()?.localeCompare(b.status?.toLowerCase()),
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (row) => moment(row).format("DD-MM-YY"),
    sorter: (a, b) =>
      a?.created?.toLowerCase()?.localeCompare(b.created?.toLowerCase()),
  },
];

export const registerUser = {
  userName: "",
  email: "",
  password: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  idenityId: "",
  faxNumber: "",
  postalAddress: "",
  gender: "",
  orgId: 0,
  userType: 0,
  gender: "",
  contact: "",
  status: "",
  checked: "",
  errorMessages: {},
  usersType: [],
  organizations: [],
  phoneNumber: 0,
};

export const locationsColumns = [
  {
    title: "Country",
    dataIndex: "countryName",
    // key: "countryName",
    render(text, record) {
      return {
        props: {
          style: { background: "white" },
        },
        children: <div>{text}</div>,
      };
    },
  },
  {
    title: "City",
    dataIndex: "cityName",
    key: "cityName",
  },
  {
    title: "Location",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created",
    dataIndex: "createdDate",
    key: "createdDate",
    render: (row) => moment(row).format("DD-MM-YY"),
  },
];

export const LocationJson = {
  submit_url: "",
  form: [
    {
      id: "1",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Country",
      name: "countryId",
      xmlName: "countryId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Country",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select Country",
          value: "",
        },
      ],
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "City",
      name: "cityId",
      xmlName: "cityId",
      popupText: "",
      helpNote: "",
      placeHolder: "Select City",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select City",
          value: "",
        },
      ],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Location Type",
      name: "type",
      xmlName: "type",
      popupText: "",
      helpNote: "",
      placeHolder: "Select Location Type",
      errorMessage: "",
      value: "",
      regex: "",
      options: [
        {
          label: "Please select Type",
          value: "",
        },
        {
          label: "Site",
          value: "site",
        },
        {
          label: "Branch",
          value: "branch",
        },
      ],
    },
    {
      id: "4",
      fieldType: "text",
      isRequired: true,
      isUpdate: true,
      name: "name",
      label: "Location Address",
      xmlName: "name",
      popupText: "",
      helpNote: "",
      placeHolder: "Location Address",
      errorMessage: "Enter valid Location Address",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    // {
    //   id: "5",
    //   fieldType: "checkbox",
    //   isRequired: false,
    //   isUpdate: true,
    //   name: "status",
    //   label: "Staus",
    //   xmlName: "status",
    //   popupText: "",
    //   helpNote: "",
    //   placeHolder: "",
    //   errorMessage: "Field Required !",
    //   value: false,
    // },
  ],
};

export const organizationEdit = {
  submit_url: "/auth/login",
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      name: "name",
      label: "Name",
      xmlName: "name",
      popupText: "",
      helpNote: "",
      placeHolder: "Organization name",
      errorMessage: "Enter valid Organization name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "Organization long name",
      name: "longName",
      xmlName: "longName",
      popupText: "",
      errorMessage: "Enter valid Organization long name",
      placeHolder: "Organization long name",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      isEdit: false,
      label: "Organization Type",
      name: "Organization type",
      xmlName: "organizationType",
      popupText: "",
      errorMessage: "Enter valid Organization Type",
      placeHolder: "Organization Type",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "4",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      isEdit: false,
      label: "Partner Organization",
      name: "partnerOrganization",
      xmlName: "partnerOrganization",
      popupText: "",
      errorMessage: "Enter valid partner Organization",
      placeHolder: "Partner Organization",
      helpNote: "",
      value: "",
      regex: "",
      hidden: true,
      options: [],
    },
    {
      id: "5",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "Website",
      name: "Organization website",
      xmlName: "website",
      popupText: "",
      errorMessage: "Enter valid Organization website",
      placeHolder: "Organization website",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
    {
      id: "6",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      label: "domain",
      name: "Organization domain",
      xmlName: "domain",
      popupText: "",
      errorMessage: "Enter valid Organization domain",
      placeHolder: "Organization domain",
      helpNote: "",
      value: "",
      regex: "",
      validators: {
        required: true,
        minLength: 10,
        pattern: "",
      },
    },
  ],
};

export const siteJson = {
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      name: "siteName",
      label: "Site Name",
      xmlName: "siteName",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter site name",
      errorMessage: "Enter valid Site Name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Organization",
      name: "organizationId",
      xmlName: "organizationId",
      popupText: "",
      errorMessage: "Enter valid Organization",
      placeHolder: "Select Organization",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Country",
      name: "countryId",
      xmlName: "countryId",
      popupText: "",
      errorMessage: "Enter valid Country",
      placeHolder: "Select country",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "4",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "City",
      name: "cityId",
      xmlName: "cityId",
      popupText: "",
      errorMessage: "Enter valid City",
      placeHolder: "Select City",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      name: "siteDescription",
      label: "description",
      xmlName: "siteDescription",
      popupText: "",
      helpNote: "",
      placeHolder: "Please enter site description",
      errorMessage: "Enter Description",
      regex: "",
      value: "",
      cols: "73",
      rows: "8",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
  ],
};

export const branchJson = {
  form: [
    {
      id: "1",
      fieldType: "text",
      isRequired: true,
      isUpdate: false,
      name: "branchName",
      label: "Branch Name",
      xmlName: "branchName",
      popupText: "",
      helpNote: "",
      placeHolder: "Enter Branch Name",
      errorMessage: "Enter valid Branch Name",
      regex: "",
      value: "",
      validators: {
        required: true,
        minLength: 30,
        pattern: "",
      },
    },
    {
      id: "2",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Organization",
      name: "organizationId",
      xmlName: "organizationId",
      popupText: "",
      errorMessage: "Enter valid Organization",
      placeHolder: "Select Organization",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "3",
      fieldType: "select",
      isRequired: true,
      isUpdate: false,
      label: "Site",
      name: "siteId",
      xmlName: "siteId",
      popupText: "",
      errorMessage: "Enter valid Site",
      placeHolder: "Select Site",
      helpNote: "",
      value: "",
      regex: "",
      options: [],
    },
    {
      id: "5",
      fieldType: "textarea",
      isRequired: true,
      isUpdate: true,
      name: "branchAddress",
      label: "Address",
      xmlName: "branchAddress",
      popupText: "",
      helpNote: "",
      placeHolder: "Please enter branch address",
      errorMessage: "Enter Description",
      regex: "",
      value: "",
      cols: "73",
      rows: "4",
      validators: {
        pattern: "",
        required: true,
        minLength: 300,
      },
    },
  ],
};
