import React, {useState} from "react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../ErrorMessage";
import show from "assets/show.png";

export const InputField = ({
  className = "form-control",
  type = "text",
  name = "",
  id = "",
  disabled = false,
  value = "",
  label = "",
  changeHandler,
  blurHandler,
  errorMessage = "",
  required = false,
  placeholder,
  labelClass,
  ThemeColor,
  activity,
  customForm
}) => {
  const [fieldType, setFieldType] = useState(
    type === "password" ? "password" : type
  );
  return (
    <div className={`relative w-full`}>
      {label !== "" ? (
        <div className={`${activity ? "" : "mb-1"}`}>
          <label
            className={`${
              activity
                ? "text-black text-xs paragraphs_font"
                : `text-xs paragraphs_font ${
                    labelClass
                      ? !ThemeColor
                        ? "text-white"
                        : "text-black"
                      : "text-white"
                  }`
            }`}
            htmlFor={name}
          >
            {label}{" "}
            {label !== "" && required ? (
              <span className="text-red-500 ml-1">*</span>
            ) : (
              ""
            )}
          </label>
        </div>
      ) : (
        ""
      )}
      <input
        type={fieldType}
        name={name}
        id={id}
        placeholder={placeholder}
        className={className}
        onChange={changeHandler}
        onBlur={blurHandler}
        value={value}
        disabled={disabled}
        required={required}
        autoComplete={type === "password" ? "new-password" : "off"}
      />
      {errorMessage ? (
        <span>
          <ErrorMessage message={errorMessage} />
        </span>
      ) : (
        ""
      )}

      {type === "password" ? (
            <div
              className={`show_password text-white w-5 absolute cursor-pointer ${customForm? "top-3.5" :"top-10"}`}
              onClick={() =>
                setFieldType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            >
              <img src={show} alt="show password" />
            </div>
          ) : (
            ""
          )}
    </div>
  );
};
InputField.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  changeHandler: PropTypes.func,
  blurHandler: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};
