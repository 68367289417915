import React, { Children, useState } from "react";
import { Button, Modal } from "antd";
export const PopModal = ({
  modalOpen,
  setModalOpen,
  title,
  Children,
  width,
  setIsEdit,
}) => {
  return (
    <>
      <div
        className={`modal-container-overlay fixed h-full  top-0 left-0 right-0 bottom-0 pt-6 pb-10 ${
          modalOpen ? "flex justify-center items-center" : "hide"
        }`}
      >
        <div
          className="absolute top-0 bottom-0 left-0 right-0 w-full z-40"
          onClick={() => {
            setModalOpen(false);
            setIsEdit(false);
          }}
        ></div>
        <div className={`flex flex-col bg-dark-theme px-10 py-5 ${width} z-50`}>
          <div className="flex justify-between items-center">
            <span className="text-white text-3xl">{title}</span>
            {/* <div
              className="text-white pt-2 hover:text-red-600 cursor-pointer"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              X
            </div> */}
          </div>
          <div>{Children}</div>
        </div>
      </div>
    </>
  );
};
