import { Select } from "../../Select";
import { useState } from "react";
import { registerUser } from "../../../utils/constants/UserManagement";

export const ConditionalLogic = ({
  datasource,
  setDataSource,
  selectedField,
  originalData,

}) => {
  const [parent, setParent] = useState("");
  const [parentOption, setParentOption] = useState([]);
  const [selectedParentOption, setSelectedParentOption] = useState("");
  const [isHide, setIsHide] = useState("");
  const parentSelectHandler = (event) => {
    setParent(event.target.value);
    const selectedField = datasource.filter(
      (item) => item.id == event.target.value
    );
    if (selectedField[0].options) {
      setParentOption(selectedField[0].options);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleParentValue = (event) => {
    setSelectedParentOption(event.target.value);
  };

  const handleHideShow = (event) => {
    setIsHide(event.target.value);
    const index = originalData.findIndex(item => item.fields.some(field => field.id === selectedField));
    if (index !== -1) {
      const updatedOriginalData = [...originalData]; // Create a copy of the originalData array
      updatedOriginalData[index].fields = updatedOriginalData[index].fields.map((fieldItem) => {
        return {
          ...fieldItem,
          parentId: parent,
          parentValue: selectedParentOption,
          show: event.target.value
        };
      });
      setDataSource(updatedOriginalData);
    }
  };



  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div className=" pt-4">
      <div className="flex">
        <div class="wrapper w-11">
          <div class="switch_box box_1 ">
            <input
              type="checkbox"
              class="switch_11"
              name="isRequired"
              //   checked={item?.isRequired}
              //   onChange={(e) => {
              //     handleChange(index, e);
              //   }}
            />
          </div>
        </div>
        <label className="margin-label paragraphs_font text-sm">
          Conditional Logic
        </label>
      </div>
      <div className="flex items-center pt-4 w-2/5">
        <div>
          <label className="mb-1 mt-2 paragraphs_font">Parent Field</label>
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            //   changeHandler={(event) =>
            //     onchangeHandler("parent", event.target.value)
            //   }
            changeHandler={(event) => parentSelectHandler(event)}
            value={parent}
            name="parent"
            // options={datasource}
            options={[
              { value: "", label: "Please select from the following" },
              ...datasource.map((item) => ({
                value: item.id,
                label: item.label,
              })),
            ]}
            required={true}
          />
        </div>
        <div className="mt-6 ml-2">
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            //   changeHandler={(event) =>
            //     onchangeHandler("parent", event.target.value)
            //   }
            value={selectedParentOption}
            name="parent"
            options={[
              { label: "Please select from the following", value: "" },
              ...parentOption,
            ]}
            disabled={isDisabled}
            required={true}
            changeHandler={(event) => handleParentValue(event)}
          />
        </div>
        <div className="mt-6 ml-2">
          <Select
            id="2"
            className="tabs-input-border px-2 py-1 rounded paragraphs_font text-sm cond-select-icon"
            //   changeHandler={(event) =>
            //     onchangeHandler("parent", event.target.value)
            //   }
            value={isHide}
            name="parent"
            options={[
              { label: "Choose to Hide and Show", value: "" },
              ...[
                { label: "Hide", value: false },
                { label: "Show", value: true },
              ],
            ]}
            required={true}
            changeHandler={(event) => handleHideShow(event)}
          />
        </div>
      </div>
      <div className="mt-2 flex items-center">
        <input
          type="checkbox"
          id="email"
          name="email"
          value="email"
          className="mr-1 cursor-pointer"
        />
        <label for="email" className="text-xs paragraphs_font">
          Send e-mail notification
        </label>
      </div>
    </div>
  );
};
