export const LOGIN = "LOGIN";
export const THEME = "THEME";
export const GETALLORGANIZATIONS = "GETALLORGANIZATIONS";
export const GETUSERSTATS = "GETUSERSTATS";
export const USERTYPES = "USERTYPES";
export const GETALLUSERS = "GETALLUSERS";
export const GETROLES = "GETROLES";
export const DELETEROLEBYID = "DELETEROLEBYID";
export const GETALLPERMISSONTYPE  ='GETALLPERMISSONTYPE';
export const SELECTEDLANGUAGE  ='SELECTEDLANGUAGE';
export const GETALLPOP  ='GETALLPOP';
export const GETPOPREGIONSTATS= 'GETPOPREGIONSTATS';
export const GETPOPTYPES  ='GETPOPTYPES';
export const GETPOPREGIONS  ='GETPOPREGIONS';
export const GETPOPZONES  ='GETPOPZONES';
export const GETPOPTERRITORIES  ='GETPOPTERRITORIES';
export const GETGEOFENCE = 'GETGEOFENCE';
export const GETALLPOPTYPE = 'GETALLPOPTYPE'
export const GETCITY = 'GETCITY'
export const GETPOPTYPESTATS = 'GETPOPTYPESTATS';
export const GETPOPORGANISATION = 'GETPOPORGANISATION';
export const GETPOPLOCATION='GETPOPLOCATION';
export const GETALLCATEGORIES='GETALLCATEGORIES';
export const GETALLSUBCATEGORIES='GETALLSUBCATEGORIES';
export const GETALLPRODUCTS='GETALLPRODUCTS';
export const DELETESUBCAT='DELETESUBCAT';
export const GETALLPROGRAMS='GETALLPROGRAMS';
export const GETORGANIZATIONSBYTYPEID='GETORGANIZATIONSBYTYPEID';
export const GETORGANIZATIONDETAILS='GETORGANIZATIONDETAILS';
export const GETLOCATIONS='GETLOCATIONS';
export const GETALLPROGRAMFORMS='GETALLPROGRAMFORMS';
export const ACTIVITYFORMDATA='ACTIVITYFORMDATA';
export const ACTIVITYFORMBYID='ACTIVITYFORMBYID';
export const GETUSERSBYGROUPID='GETUSERSBYGROUPID';
export const GETGROUPBYROLEID='GETGROUPBYROLEID';
export const GETPOPTYPESBYPOPID='GETPOPTYPESBYPOPID';
export const DELETEUSERBYID='DELETEUSERBYID';
export const ORGANIZAIONTYPES='ORGANIZAIONTYPES';
export const ORGALLSITES='ORGALLSITES';
export const SITEBRANCHES='SITEBRANCHES';
export const GROUPUSERS='GROUPUSERS';
export const GETALLSITES='GETALLSITES';
export const GETALLBRANCHES='GETALLBRANCHES';
export const GETPERMISSONS='GETPERMISSONS';

export const GET_LIST = "GET_LIST";
export const GET_FORM = "GET_FORM";
export const GET_STATUS = 'GET_STATUS';
export const GET_SITEID = 'GET_SITEID';
export const DELETE_POC = "DELETE_POC";
export  const GET_LOOKUP = 'GET_LOOKUP';
export const GET_APPTYPE = 'GET_APPTYPE';
export const GET_REPORTS = 'GET_REPORTS';
export const GET_PROFILE = "GET_PROFILE";
export const RESET_VALUE = "RESET_VALUE";
export const GET_QUERIES = 'GET_QUERIES';
export const DELETE_ROLE = "DELETE_ROLE";
export const GET_ALL_POC = "GET_ALL_POC";
export const DELETE_GROUP = "DELETE_GROUP";
export const GET_ALL_ROLE = "GET_ALL_ROLE";
export const GET_APP_TYPE = 'GET_APP_TYPE';
export const GET_OPERATOR = 'GET_OPERATOR';
export const GET_REPEATER = 'GET_REPEATER';
export const GET_BM_QUERY = 'GET_BM_QUERY';
export const GETALLGROUPS = 'GETALLGROUPS';
export const GETALLPERMISSON = 'GETALLPERMISSON';

export  const ADD_REPEATER = 'ADD_REPEATER';
export const STATUS_ACTION = "STATUS_ACTION";
export const GET_ALL_GROUP = "GET_ALL_GROUP";
export  const GET_PARAMETER = 'GET_PARAMETER';
export  const GET_HAND_HELD = 'GET_HAND_HELD';
export  const ADD_HAND_HELD = 'ADD_HAND_HELD';
export const GET_USER_GROUP = 'GET_USER_GROUP';
export const GET_ROLE_GROUP = 'GET_ROLE_GROUP';
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_DATA_BY_ID = "GET_DATA_BY_ID";
export const API_CALL_CLEAR = "API_CALL_CLEAR";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const GET_ALL_REPORTS = 'GET_ALL_REPORTS';
export const SET_LETTER_DATA = 'SET_LETTER_DATA';
export const SAVE_APPLICAION = "SAVE_APPLICAION";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const GET_APPLICATION_FORM = "RESET_VALUE";
export  const REMOVE_REPEATER = 'REMOVE_REPEATER';
export const SET_SELECTED_POC = "SET_SELECTED_POC";
export const GET_ALL_EMPLOYEE = "GET_ALL_EMPLOYEE";
export  const ADD_BASE_STATION = 'ADD_BASE_STATION';
export  const REMOVE_HAND_HELD = 'REMOVE_HAND_HELD';

export  const GET_BASE_STATION = 'GET_BASE_STATION';
export const REMOVE_DATA_BY_ID = "REMOVE_DATA_BY_ID";
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";
export const GET_ASSIGNED_USER = 'GET_ASSIGNED_USER';
export const UPDATE_QUERY_LIST = 'UPDATE_QUERY_LIST';
export const API_CALL_REJECTED = "API_CALL_REJECTED";
export const CLEAR_API_BY_NAME = "CLEAR_API_BY_NAME";
export const DRAFT_APPLICATION = "DRAFT_APPLICATION";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
export const SET_PROPOSAL_DATA = "SET_PROPOSAL_DATA";
export const GET_WORK_FLOW_FORM = "GET_WORK_FLOW_FORM";
export const API_CALL_REQUESTED = "API_CALL_REQUESTED";
export const GET_ALERT_TIMELINE = 'GET_ALERT_TIMELINE';
export const API_CALL_FULFILLED = "API_CALL_FULFILLED";
export const SET_SELECTED_GROUP = "SET_SELECTED_GROUP";
export const SUBMIT_APPLICATION = "SUBMIT_APPLICATION";
export  const GET_STAFF_BM_QUERY = 'GET_STAFF_BM_QUERY';
export const GET_USER_PERMISSION= 'GET_USER_PERMISSION';
export const GET_SITE_ID_REPORTS = 'GET_SITE_ID_REPORTS';
export const GET_DASHBOARD_STATS = "GET_DASHBOARD_STATS";
export const GET_COMPLAINT_DASHBOARD_STATS = "GET_COMPLAINT_DASHBOARD_STATS";
export const HISTORY_APPLICATION = "HISTORY_APPLICATION";
export const GET_COMPLAINTS_LIST = 'GET_COMPLAINTS_LIST';

export  const REMOVE_BASE_STATION = 'REMOVE_BASE_STATION';
export const GET_BOARD_PERMISSION = 'GET_BOARD_PERMISSION';
export const Returned_APPLICATION = "Returned_APPLICATION";
export const SET_SELECTED_LICENSE = "SET_SELECTED_LICENSE";
export const GET_SINGLE_COMPLAINT = 'GET_SINGLE_COMPLAINT';
export const UPDATE_QUERY_LISTING = 'UPDATE_QUERY_LISTING';
export  const GET_MOBILE_CONNECTED = 'GET_MOBILE_CONNECTED';
export  const ADD_MOBILE_CONNECTED = 'ADD_MOBILE_CONNECTED';
export const SET_SELECTED_EMPLOYEE = "SET_SELECTED_EMPLOYEE";
export const GET_NETWORK_COMPONENT = 'GET_NETWORK_COMPONENT';
export const GET_GMDSS_CERTIFICATE = 'GET_GMDSS_CERTIFICATE';
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const SET_SELECTED_SPECTRUM = 'SET_SELECTED_SPECTRUM';
export const GET_APPLICANT_RESPONSE = 'GET_APPLICANT_RESPONSE';
export const GET_GMDSS_HISTORY_LIST = 'GET_GMDSS_HISTORY_LIST';
export const SET_CERTIFICATE_STATUS = 'SET_CERTIFICATE_STATUS';
export  const GET_CHOOSE_APPLICATION = 'GET_CHOOSE_APPLICATION';
export  const GET_HANDHELD_CONNECTED = 'GET_HANDHELD_CONNECTED';
export const WLL_HISTORY_APPLICATION = "WLL_HISTORY_APPLICATION";
export const RESET_APPLICATION_VALUE = "RESET_APPLICATION_VALUE";
export const SET_SELECTED_ATTACHMENT = "SET_SELECTED_ATTACHMENT";

export  const ADD_HAND_HELD_CONNECTED = 'ADD_HAND_HELD_CONNECTED';
export  const REMOVE_MOBILE_CONNECTED = 'REMOVE_MOBILE_CONNECTED';
export const DELETE_DRAFT_APPLICATION = "DELETE_DRAFT_APPLICATION";
export const GET_APPROVED_APPLICATION = "GET_APPROVED_APPLICATION";
export const UPDATE_NOTIFICATION_LIST = 'UPDATE_NOTIFICATION_LIST';
export const GET_ASSIGNED_APPLICATION = "GET_ASSIGNED_APPLICATION";
export const SET_SELECTED_SPECTRUM_MW = "SET_SELECTED_SPECTRUM_MW";
export const GET_REJECTED_APPLICATION = "GET_REJECTED_APPLICATION";
export const GET_RETUNED_APPLICATIONS = 'GET_RETUNED_APPLICATIONS';
export const GET_MW_EQUIPMENT_DETAILS = 'GET_MW_EQUIPMENT_DETAILS';
export const GET_DASHBOARD_CMTOS_STATS = 'GET_DASHBOARD_CMTOS_STATS';
export const GET_UPDATE_ALERT_TIMELINE = 'GET_UPDATE_ALERT_TIMELINE';
export const GET_BTS_EQUIPMENT_DETAILS = 'GET_BTS_EQUIPMENT_DETAILS';
export const SET_SELECTED_COMPANY_INFO = "SET_SELECTED_COMPANY_INFO";
export const SET_SELECTED_SPECTRUM_BTS = "SET_SELECTED_SPECTRUM_BTS";
export const GET_CANCELLED_APPLICATIONS = 'GET_CANCELLED_APPLICATIONS';
export const GET_COMPLETED_APPLICATIONS = 'GET_COMPLETED_APPLICATIONS';
export const GET_GMDSS_REJECTION_REASON = 'GET_GMDSS_REJECTION_REASON';
export const REMOVE_HAND_HELD_CONNECTED = 'REMOVE_HAND_HELD_CONNECTED';
export const GET_APPLICATION_OBSERVATION = "GET_APPLICATION_OBSERVATION";
export const GET_DRILL_DOWN_STATS_ACTION = 'GET_DRILL_DOWN_STATS_ACTION';
export const GET_COMPLAINT_DRILL_DOWN_STATS_ACTION = 'GET_COMPLAINT_DRILL_DOWN_STATS_ACTION';
export const GET_EMPLOYEE_DRILL_DOWN_STATS_ACTION = 'GET_EMPLOYEE_DRILL_DOWN_STATS_ACTION';

export const DELETE_MW_EQUIPMENT_DETAILS = 'DELETE_MW_EQUIPMENT_DETAILS';
export const DELETE_BTS_EQUIPMENT_DETAILS = 'DELETE_BTS_EQUIPMENT_DETAILS';
export const GET_DASHBOARD_PROVINCE_STATS = 'GET_DASHBOARD_PROVINCE_STATS';
export const SET_SELECTED_WLL_SPECTRUM_FDD = "SET_SELECTED_WLL_SPECTRUM_FDD";
export const SET_SELECTED_WLL_SPECTRUM_TDD = "SET_SELECTED_WLL_SPECTRUM_TDD";
export const GET_GMDSS_SINGLE_USER_HISTORY = 'GET_GMDSS_SINGLE_USER_HISTORY';
export  const ADD_REPEATER_MOBILE_CONNECTED = 'ADD_REPEATER_MOBILE_CONNECTED';
export  const GET_REPEATER_MOBILE_CONNECTED = 'GET_REPEATER_MOBILE_CONNECTED';
export const GET_APPLICATION_BAR_CHART_STATS = 'GET_APPLICATION_BAR_CHART_STATS';
export  const GET_REPEATER_HANDHELD_CONNECTED = 'GET_REPEATER_HANDHELD_CONNECTED';
export const Returned_APPLICATION_OBSERVATION = "Returned_APPLICATION_OBSERVATION";
export const GET_PARTIAL_APPROVED_APPLICATION = "GET_PARTIAL_APPROVED_APPLICATION";
export  const ADD_REPEATER_HAND_HELD_CONNECTED = 'ADD_REPEATER_HAND_HELD_CONNECTED';
export  const REMOVE_REPEATER_MOBILE_CONNECTED = 'REMOVE_REPEATER_MOBILE_CONNECTED';
export const GET_APPLICATION_SECURITY_CLEARANCE = "GET_APPLICATION_SECURITY_CLEARANCE";
export  const REMOVE_REPEATER_HAND_HELD_CONNECTED = 'REMOVE_REPEATER_HAND_HELD_CONNECTED';
export  const EMPTY_STATS_STORE = 'EMPTY_STATS_STORE';
export const SET_CURRENT_PAGE_SIZE = 'SET_CURRENT_PAGE_SIZE';
export const GET_DASHBOARD_STATS_NEW = 'GET_DASHBOARD_STATS_NEW';