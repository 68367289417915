import { useEffect, useState } from "react";
import { Select } from "../Select";
import { TypeText } from "./typeTextFields";
import { TypeSelect } from "./typeSelectFields";
import { TypeTextValidations } from "./typeTextFields";
import { ConditionalLogic } from "./ConditionalLogic";
import { useSelector } from "react-redux";

const tab_buttons = [
  {
    id: 0,
    name: "General",
  },
  {
    id: 1,
    name: "Validation",
  },
  {
    id: 2,
    name: "Conditional Logic",
  },
];

const fieldsTypeOption = [
  {
    label: "Select Field",
    value: "",
  },
  {
    name: "Text",
    value: "text",
  },
  {
    name: "Select",
    value: "select",
  },
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Radio Group",
    value: "radio",
  },
];

export const TabsView = ({
  dataSource,
  setExpandedIndex,
  recordId,
  setDataSource,
  originalData,
  setFieldsModel,
  currentItem,
  setCurrentItem,
  handleSelectType,
}) => {
  const [tabcount, setTabcount] = useState(0);
  const [state, setState] = useState([...dataSource]);
  const ThemeColor = useSelector((state) => state?.common?.Theme);
  useEffect(() => {
    setState([...dataSource]);
  }, [dataSource]);

  const handleChange = (index, event) => {
    const name = event.target.name;
    const field = state[index];
    if (field && name) {
      if (name === "minLength") {
        const value =
          event.target.value !== ""
            ? parseInt(event.target.value)
            : event.target.value;
        field[name] = value;
        setState((state) =>
          state.map((item, index) =>
            index === state[index] ? { ...item, [name]: value } : item
          )
        );
      } else if (name === "isRequired") {
        const value = event.target.checked;
        field[name] = value;
        setState((state) =>
          state.map((item, index) =>
            index === state[index] ? { ...item, [name]: value } : item
          )
        );
      } else {
        const value = event.target.value;
        field[name] = value;
        setState((state) =>
          state.map((item, index) =>
            index === state[index] ? { ...item, [name]: value } : item
          )
        );
      }
    }
  };

  const changeData = () => {
    let itemObject = originalData.filter((item, index) => {
      if (item.key === currentItem?.key) {
        return item;
      }
    });
    itemObject[0].fields = [...state];
    setDataSource([...originalData]);
  };

  const addNewOption = (newFor, index) => {
    if (newFor === "radio") {
      const newRadioButton = { label: "New Radio Button", value: "new option" };
      let duplicateOptions = [...state[index].radioButtons];
      duplicateOptions.push(newRadioButton);
      state[index].radioButtons = duplicateOptions;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index]
            ? { ...item, radioButtons: duplicateOptions }
            : item
        )
      );
    } else {
      const newOption = { label: "New Option", value: "new option" };
      let duplicateOptions = [...state[index].options];
      duplicateOptions.push(newOption);
      state[index].options = duplicateOptions;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index] ? { ...item, options: duplicateOptions } : item
        )
      );
    }
  };

  const deleteItem = (handle, index, optIndex) => {
    const deleteCase = handle;
    if (deleteCase === "radio") {
      let radioButtons = [...state[index].radioButtons];
      let deleteItem = radioButtons.filter((_, i) => i !== optIndex);
      state[index].radioButtons = deleteItem;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index] ? { ...item, radioButtons: deleteItem } : item
        )
      );
    } else {
      let option = [...state[index].options];
      let deleteItem = option.filter((_, i) => i !== optIndex);
      state[index].options = deleteItem;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index] ? { ...item, options: deleteItem } : item
        )
      );
    }
  };

  const handleChangeOption = (changeCase, index, optIndex, e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (changeCase === "radio") {
      let buttonGroup = [...state[index].radioButtons];
      let singleButton = buttonGroup[optIndex];
      singleButton[name] = value;
      singleButton["value"] = value;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index] ? { ...item, options: singleButton } : item
        )
      );
    } else {
      let allOptions = [...state[index].options];
      let singleOption = allOptions[optIndex];
      singleOption[name] = value;
      singleOption["value"] = value;
      setState((state) =>
        state.map((item, ind) =>
          ind === state[index] ? { ...item, options: singleOption } : item
        )
      );
    }
  };

  return (
    <>
      <div className="w-full pb-5 h-full tab-pannel">
        <div className=" w-full">
          <div className="flex justify-center tabs-bg">
            <div className="w-3/5 h-full">
              <div className="flex items-center pt-4 pb-4">
                {tab_buttons.map((tab_items, i) => (
                  <>
                    <div
                      className={`flex items-center mr-4 paragraphs_font text-sm ${
                        tab_items.id === tabcount ? "active-tab" : ""
                      }`}
                      onClick={() => {
                        setTabcount(tab_items.id);
                      }}
                      id={tab_items.id}
                      key={tab_items.id}
                    >
                      <div className={` flex`}> {tab_items?.name}</div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <div
            className="w-full flex justify-center"
            onMouseEnter={() => {
              setCurrentItem(currentItem);
            }}
          >
            <div className="w-3/5">
              <div>
                <div
                  className={`tab_content ${
                    0 === tabcount ? "active show" : "hide"
                  }`}
                  id={0}
                >
                  <div className="flex cursor-default">
                    <div>
                      <div className="pt-4">
                        {state &&
                          state.length &&
                          state?.map((item, index) => {
                            if (
                              item.fieldType === "text" &&
                              recordId === item.id
                            ) {
                              return (
                                <>
                                  <div className="flex pt-4 items-center">
                                    <Select
                                      id={item.id}
                                      label="Field Type"
                                      className={`tabs-select-border outline-none px-2 py-1 rounded`}
                                      value={state?.activityTypeId}
                                      name="fieldType"
                                      options={fieldsTypeOption}
                                      required={true}
                                      labelClass
                                      activity={ThemeColor}
                                      changeHandler={(event) =>
                                        handleSelectType(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    />
                                    <div className="pt-6">
                                      <button
                                        className="bg-light-blue-button ml-3 py-1 px-2 rounded text-white"
                                        onClick={() => {
                                          setFieldsModel(true);
                                          setExpandedIndex(index);
                                        }}
                                      >
                                        Browse Fields
                                      </button>
                                    </div>
                                  </div>
                                  <TypeText
                                    item={item}
                                    index={index}
                                    handleChange={handleChange}
                                    changeData={changeData}
                                  />
                                </>
                              );
                            } else if (
                              item.fieldType === "date" &&
                              recordId === item.id
                            ) {
                              return (
                                <>
                                  <div className="flex pt-4 items-center">
                                    <Select
                                      id={item.id}
                                      label="Field Type"
                                      className={`tabs-select-border outline-none px-2 py-1 rounded`}
                                      value={state?.activityTypeId}
                                      name="fieldType"
                                      options={fieldsTypeOption}
                                      required={true}
                                      labelClass
                                      activity={ThemeColor}
                                      changeHandler={(event) =>
                                        handleSelectType(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    />
                                    <div className="pt-6">
                                      <button
                                        className="bg-light-blue-button ml-3 py-1 px-2 rounded text-white"
                                        onClick={() => {
                                          setFieldsModel(true);
                                          setExpandedIndex(index);
                                        }}
                                      >
                                        Browse Fields
                                      </button>
                                    </div>
                                  </div>
                                  <TypeText
                                    item={item}
                                    index={index}
                                    handleChange={handleChange}
                                    changeData={changeData}
                                  />
                                </>
                              );
                            } else if (
                              item.fieldType === "select" &&
                              recordId === item.id
                            ) {
                              return (
                                <>
                                  <div className="flex pt-4 items-center">
                                    <Select
                                      id={item.id}
                                      label="Field Type"
                                      className={`tabs-select-border outline-none px-2 py-1 rounded`}
                                      value={state?.activityTypeId}
                                      name="fieldType"
                                      options={fieldsTypeOption}
                                      required={true}
                                      labelClass
                                      activity={ThemeColor}
                                      changeHandler={(event) =>
                                        handleSelectType(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    />
                                    <div className="pt-6">
                                      <button
                                        className="bg-light-blue-button ml-3 py-1 px-2 rounded text-white"
                                        onClick={() => {
                                          setFieldsModel(true);
                                          setExpandedIndex(index);
                                        }}
                                      >
                                        Browse Fields
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <TypeSelect
                                      item={item}
                                      index={index}
                                      handleChange={handleChange}
                                      changeData={changeData}
                                      addNewOption={addNewOption}
                                      deleteItem={deleteItem}
                                      handleChangeOption={handleChangeOption}
                                    />
                                  </div>
                                </>
                              );
                            } else if (
                              item.fieldType === "radio" &&
                              recordId === item.id
                            ) {
                              return (
                                <>
                                  <div className="flex pt-4 items-center">
                                    <Select
                                      id={item.id}
                                      label="Field Type"
                                      className={`tabs-select-border outline-none px-2 py-1 rounded`}
                                      value={state?.activityTypeId}
                                      name="fieldType"
                                      options={fieldsTypeOption}
                                      required={true}
                                      labelClass
                                      activity={ThemeColor}
                                      changeHandler={(event) =>
                                        handleSelectType(
                                          index,
                                          event.target.value
                                        )
                                      }
                                    />
                                    <div className="pt-6">
                                      <button
                                        className="bg-light-blue-button ml-3 py-1 px-2 rounded text-white"
                                        onClick={() => {
                                          setFieldsModel(true);
                                          setExpandedIndex(index);
                                        }}
                                      >
                                        Browse Fields
                                      </button>
                                    </div>
                                  </div>
                                  <div>
                                    <TypeSelect
                                      item={item}
                                      index={index}
                                      handleChange={handleChange}
                                      changeData={changeData}
                                      addNewOption={addNewOption}
                                      deleteItem={deleteItem}
                                      handleChangeOption={handleChangeOption}
                                    />
                                  </div>
                                </>
                              );
                            }
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab_content ${
                    1 === tabcount ? "active show" : "hide"
                  }`}
                  id={1}
                >
                  <div className="tab_item_content">
                    {state &&
                      state.length &&
                      state?.map((item, index) => {
                        if (item.fieldType === "text" && recordId === item.id) {
                          return (
                            <TypeTextValidations
                              item={item}
                              index={index}
                              handleChange={handleChange}
                              changeData={changeData}
                            />
                          );
                        } else if (
                          item.fieldType === "date" &&
                          recordId === item.id
                        ) {
                          return (
                            <TypeTextValidations
                              item={item}
                              index={index}
                              handleChange={handleChange}
                              changeData={changeData}
                            />
                          );
                        }
                        else if (
                          item.fieldType === "select" &&
                          recordId === item.id
                        ) {
                          return (
                            <TypeTextValidations
                              item={item}
                              index={index}
                              handleChange={handleChange}
                              changeData={changeData}
                            />
                          );
                        }
                        else if (
                          item.fieldType === "radio" &&
                          recordId === item.id
                        ) {
                          return (
                            <TypeTextValidations
                              item={item}
                              index={index}
                              handleChange={handleChange}
                              changeData={changeData}
                            />
                          );
                        }
                      })}
                  </div>
                </div>
                <div
                  className={`tab_content ${
                    2 === tabcount ? "active show" : "hide"
                  }`}
                  id={2}
                >
                  <div className="tab_item_content">
                    {/*{state &&*/}
                    {/* state.length &&*/}
                    {/* state?.map((item, index) => {*/}
                    {/*   return (*/}
                    <ConditionalLogic
                      // item={item}
                      // index={index}
                      datasource={dataSource}
                      setDataSource={setDataSource}
                      handleChange={handleChange}
                      changeData={changeData}
                      selectedField={recordId}
                      originalData={originalData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
