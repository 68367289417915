import React from "react";

export const SingleCard = ({
  title,
  description,
  className,
  icon,
  ThemeColor,
  redirect,
  width,
  textAlign,
  direction,
  padding,
  handleClick,
  itemKey,
}) => {
  return (
    <div
      className={` dashboard_card_bg_color dashboard_card_main py-6 px-4 card_p ${className} ${direction}`}
      onClick={() => {
        handleClick(itemKey, redirect);
      }}
    >
      <div className="dashboard_card_left w-1/6 pt-2">
        <img src={icon} alt="icon" className={`${width} ${padding} h-10`} />
      </div>
      <div className="dashboard_card_inner_right ">
        <div className={`card_title gradient_text ${textAlign}`}>{title}</div>
        <div
          className={`card_description paragraphs_font para_color font-thin ${textAlign}`}
        >
          {description}
        </div>
      </div>
    </div>
  );
};
