import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function CategoryManagementReducer(
  state = StoreState.categoryManagement,
  action
) {
  switch (action.type) {
    case ActionType.GETALLCATEGORIES:
      var s = cloneDeep(state);
      s.getAllCategories = action?.data?.data;
      return s;
    case ActionType.GETALLSUBCATEGORIES:
      var s1 = cloneDeep(state);
      s1.getAllSubCategories = action?.data?.data;
      return s1;
    case ActionType.GETALLPRODUCTS:
      var s2 = cloneDeep(state);
      s2.getAllProducts = action?.data?.data;
      return s2;
    case ActionType.DELETESUBCAT:
      var s3 = cloneDeep(state);
      const subCategories = s3.getAllSubCategories.filter(function (value) {
        return value.id !== action.data;
      });
      s3.getAllSubCategories = subCategories;
      return s3;
    default:
      return state;
  }
}
