import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function ActivityManagementReducer(
  state = StoreState.activityManagement,
  action
) {
  switch (action.type) {
    case ActionType.GETALLPROGRAMS:
      var s = cloneDeep(state);
      s.getAllPrograms = action?.data?.data;
      return s;
    case ActionType.GETALLPROGRAMFORMS:
      var s1 = cloneDeep(state);
      s1.getProgramForms = action?.data?.data;
      return s1;
    case ActionType.ACTIVITYFORMDATA:
      var s2 = cloneDeep(state);
      s2.activityFormData = action?.data;
      return s2;
    case ActionType.ACTIVITYFORMBYID:
      var s2 = cloneDeep(state);
      s2.activityForm = action?.data.data;
      return s2;
    default:
      return state;
  }
}
