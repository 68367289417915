import home from "assets/home.png";
import group from "assets/group.png";
import location from "assets/location.png";
import profit from "assets/profit.png";
import file_chart_line from "assets/file_chart_line.png";
import Group_management from "assets/Group_management.png";
import home_unfilled from "assets/home_unfilled.png";
import pop_management from "assets/pop_management.png";

export const MainMenu = [
  {
    id: 1,
    label: "Home",
    link: "/main-dashboard",
    icon: `${home_unfilled}`,
    key: "A01",
  },
  {
    id: 2,
    label: "Activities Management",
    link: "/activity-management",
    icon: `${profit}`,
    key: "B02",
  },
  {
    id: 3,
    label: "User Management",
    link: "/user-management",
    icon: `${group}`,
    key: "C03",
    items: [
      {
        id: 4,
        label: "Group Management",
        link: "/group-management",
        icon: "",
        key: "C1",
      },
      {
        id: 5,
        label: "Role Management",
        link: "/roles-management",
        icon: "",
        key: "C2",
      },
      {
        id: 6,
        label: "Permission Management",
        link: "/permission-management",
        icon: "",
        key: "C3",
      },
    ],
  },
  {
    id: 7,
    label: "Organization Management",
    link: "/organization-management",
    icon: `${Group_management}`,
    key: "D04",
    items: [
      {
        id: 18,
        label: "Site Management",
        link: "/site-management",
        icon: "",
        key: "D1",
      },
      {
        id: 19,
        label: "Branch Management",
        link: "/branch-management",
        icon: "",
        key: "D2",
      },
      {
        id: 20,
        label: "Category Management",
        link: "/category-management",
        icon: "",
        key: "D3",
      },
    ],
  },
  {
    id: 8,
    label: "POP Management",
    link: "/pop-type-management",
    icon: `${pop_management}`,
    key: "E05",
    items: [
      {
        id: 14,
        label: "POP Type",
        link: "/pop-types",
        icon: "",
        key: "E1",
      },
      {
        id: 15,
        label: "POP Zone",
        link: "/pop-zone",
        icon: "",
        key: "E2",
      },
      {
        id: 16,
        label: "POP Region",
        link: "/pop-region",
        icon: "",
        key: "E3",
      },
      {
        id: 17,
        label: "POP Territory",
        link: "/pop-territory",
        icon: "",
        key: "E4",
      },
    ],
  },
  {
    id: 10,
    label: "Geo Fence",
    link: "/geo-fence",
    icon: `${location}`,
    key: "F01",
  },
];
