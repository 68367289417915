import React, { useState } from "react";
import PropTypes from "prop-types";
import arrow_menu from "assets/arrow_menu.svg";
import arrow_down_menu from "assets/arrow_down_menu.svg";
import { Link } from "react-router-dom";

export const SidebarNav = ({ className, items, handleNavigation }) => {
  const [state, setState] = useState("");
  const [subMenu, setSubMenu] = useState("");
  const openSubmenu = (param) => {
    if (subMenu === param) {
      setSubMenu("");
    } else {
      setSubMenu(param);
    }
  };
  const menuHandler = (key) => {
    setState(key);
    setSubMenu("");
  };
  const subMenuHandler = (key) => {
    setState(key);
  };
  return (
    <nav
      className={`${className} para_color cursor-pointer ml-5 pr-2 pt-6 w-64`}
    >
      {items.length ? (
        items.map((data, index) => {
          if (data.link) {
            return (
              <div
                key={index}
                onClick={() => {
                  menuHandler(data.key);
                  handleNavigation(data.key)
                }}
                className={`sidebar_menu_item mb-4 side-navbar-item px-1 py-2  ${
                  state === data.key ? "active text-blue-500" : ""
                }`}
              >
                <Link
                  className={`flex ${
                    state === data.key ? "active text-blue-500" : ""
                  }`}
                  to={data.link}
                >
                  {data?.icon ? (
                    <span className="sidebar_menu_icon mr-3">
                      <img src={data?.icon} alt="" />
                    </span>
                  ) : (
                    ""
                  )}
                  {data.label}{" "}
                </Link>
              </div>
            );
          } else {
            return (
              <div className={"main_nav mb-4 "} key={index}>
                <div className="sidbar_menu">
                  <div
                    className="menu_item flex side-navbar-item px-1 py-2 items-center"
                    onClick={() => openSubmenu(data.key)}
                  >
                    {data?.icon ? (
                      <span className="sidebar_menu_icon mr-3">
                        <img src={data?.icon} alt="" />
                      </span>
                    ) : (
                      ""
                    )}
                    {data.label}
                    <span className="sidebar_menu_icon_after pt-1 ml-12">
                      <img
                        src={
                          subMenu === data.key ? arrow_down_menu : arrow_menu
                        }
                        alt=""
                      />
                    </span>
                  </div>
                  <ul
                    className={`ml-4 mt-2 mb-3 submenu ${
                      subMenu === data.key ? "block" : "hidden"
                    }`}
                  >
                    {data?.items && data?.items.length > 0
                      ? data?.items.map((subItem, subIndex) => {
                          return (
                            <Link
                              className={
                                state === subItem.key
                                  ? "active text-blue-500"
                                  : ""
                              }
                              to={subItem.link}
                            >
                              <div
                                key={subIndex}
                                onClick={() => subMenuHandler(subItem.key)}
                                className={`mb-3 side-navbar-item px-1 py-1${
                                  state === subItem.key
                                    ? "sidebar_menu_item active"
                                    : "sidebar_menu_item"
                                }`}
                              >
                                {data?.icon ? (
                                  <span className="sidebar_menu_icon_sub mr-1">
                                    &#8226;
                                  </span>
                                ) : (
                                  ""
                                )}
                                {subItem.label}
                              </div>
                            </Link>
                          );
                        })
                      : null}
                  </ul>
                </div>
              </div>
            );
          }
        })
      ) : (
        <div className="text-white">Loading ...</div>
      )}
    </nav>
  );
};
SidebarNav.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
};
