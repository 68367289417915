import { THEME, SELECTEDLANGUAGE } from "./Types";

export function ChoseTheme(data) {
  return {
    type: THEME,
    data,
  };
}

export function SelectedLanguage(data) {
  return {
    type: SELECTEDLANGUAGE,
    data,
  };
}

