import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function UserManagementReducer(
  state = StoreState.userManagement,
  action
) {
  switch (action.type) {
    case ActionType.GETALLORGANIZATIONS:
      var s = cloneDeep(state);
      s.getAllOrganizations = action?.data?.data;
      return s;
    case ActionType.GETUSERSTATS:
      var s1 = cloneDeep(state);
      s1.GetUserStatistics = action?.data?.data;
      return s1;
    case ActionType.GETALLUSERS:
      var s2 = cloneDeep(state);
      s2.getAllUsers = action?.data?.data;
      return s2;
    case ActionType.GETROLES:
      var s3 = cloneDeep(state);
      s3.getAllRoles = action?.data?.data;
      return s3;
    case ActionType.DELETEROLEBYID:
      var s4 = cloneDeep(state);
      const deleteItem = s4.getAllRoles.filter((item) => {
        return item.id !== action?.data;
      });
      s4.getAllRoles = deleteItem;
      return s4;
    case ActionType.GETALLGROUPS:
      var s5 = cloneDeep(state);
      s5.getAllGroups = action.data.data;
      return s5;
    case ActionType.GETALLPERMISSON:
      var s5 = cloneDeep(state);
      s5.getAllPermissons = action.data.data;
      return s5;
    case ActionType.GETALLPERMISSONTYPE:
      var s6 = cloneDeep(state);
      s6.getAllPermissonsType = action.data.data;
      return s6;
    case ActionType.GETORGANIZATIONSBYTYPEID:
      var s7 = cloneDeep(state);
      s7.organizationByType = action.data.data;
      return s7;
    case ActionType.GETORGANIZATIONDETAILS:
      var s8 = cloneDeep(state);
      s8.organizationDetails = action.data.data;
      return s8;
    case ActionType.GETLOCATIONS:
      var s9 = cloneDeep(state);
      s9.locations = action.data.data;
      return s9;
    case ActionType.GETUSERSBYGROUPID:
      var s10 = cloneDeep(state);
      s10.groupUsers = action.data.data;
      return s10;
    case ActionType.GETGROUPBYROLEID:
      var s11 = cloneDeep(state);
      s11.roleGroup = action.data.data;
      return s11;
    case ActionType.DELETEUSERBYID:
      var s12 = cloneDeep(state);
      const deleteUsers = s12.getAllUsers.filter(
        (item) => !action?.data?.ids.includes(item.id)
      );
      s12.getAllUsers = deleteUsers;
      return s12;
    case ActionType.ORGANIZAIONTYPES:
      var s13 = cloneDeep(state);
      s13.organizationTypes = action?.data?.data;
      return s13;
    case ActionType.ORGALLSITES:
      var s14 = cloneDeep(state);
      s14.sitiesData = action?.data?.data;
      return s14;
    case ActionType.SITEBRANCHES:
      var s15 = cloneDeep(state);
      s15.sitieBranches = action?.data?.data;
      return s15;
    case ActionType.GROUPUSERS:
      var s16 = cloneDeep(state);
      s16.allUsersOfGroup = action?.data?.data;
      return s16;
    case ActionType.GETALLSITES:
      var s17 = cloneDeep(state);
      s17.sitesData = action?.data?.data;
      return s17;
    case ActionType.GETALLBRANCHES:
      var s18 = cloneDeep(state);
      s18.branchesData = action?.data?.data;
      return s18;
    case ActionType.GETPERMISSONS:
      var s19 = cloneDeep(state);
      s19.allPermissions = action?.data?.data;
      return s19;
    default:
      return state;
  }
}
