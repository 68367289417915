import React from "react";

export const CheckGroup = ({ checkboxes, onChange, layout }) => (
  <>
    {checkboxes.map((checkbox) => (
      <div key={checkbox.id} className="mt-3 ">
        <input
          type="checkbox"
          className="cursor-pointer"
          id={checkbox.id}
          onChange={() => onChange(checkbox.id)}
        />
        <label htmlFor={checkbox.id} className="paragraphs_font text-sm ml-1">{checkbox.label}</label>
      </div>
    ))}
  </>
);
 