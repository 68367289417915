import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Logo_sidebar_nav from "assets/Logo_sidebar_nav.png";
import long_logo_sidebar_nav from "assets/long_logo_sidebar_nav.png";
import Settings from "assets/Settings.png";
import { SidebarNav } from "../SidebarNav/SidebarNav";
import { MainMenu } from "utils/constants/SibarBarMenu";
import { setCookie } from "services/session/cookies";

const SidebarNavigation = ({ setSideNavigationShow, sideNavigationShow }) => {
  useEffect(() => {
    var sideBar = document.getElementsByClassName("side-navbar")[0];
    if (sideNavigationShow) {
      sideBar.classList.add("width-250");
    } else {
      sideBar.classList.add("w-0");
    }
  }, []);

  const handleNavigation = (key) => {
    const selectedItem = MainMenu.find((item) => item.key === key);
    if (selectedItem) {
      const { label, link, items } = selectedItem;
      const mappedItems = items
        ? items.map((item) => ({ label: item.label, value: item.link }))
        : [];
      let result = [{ label, value: link }];
      if (mappedItems.length > 0) {
        result = result.concat(mappedItems);
      }
      setCookie("navItems", JSON.stringify(result));
    }
  };
  return (
    <>
      <div className="absolute w-full h-full">
        <div
          className="fixed top-0 bottom-0 left-0 right-0 w-full z-700 modal-container-overlay"
          onClick={() => {
            setSideNavigationShow(!sideNavigationShow);
          }}
        ></div>
        <div className="fixed top-0 z-800 bg-black h-full text-white side-navbar flex flex-col justify-between">
          <div>
            <div
              className="mb-6 mt-4 pl-4"
              onClick={() => handleNavigation("A01")}
            >
              <Link
                to="/main-dashboard"
                className="sidebar_logo flex items-center"
              >
                <img src={Logo_sidebar_nav} alt="logo" className="mr-3 h-11" />
                <img
                  src={long_logo_sidebar_nav}
                  alt="long_logo_sidebar_nav"
                  className="h-5"
                />
              </Link>
            </div>
            <SidebarNav
              className="dashboard_nav"
              items={MainMenu}
              handleNavigation={handleNavigation}
            />
          </div>
          <div className="mb-3">
            <div className="para_color cursor-pointer flex ml-5 pr-2">
              <span className="sidebar_menu_icon mr-3 ">
                <img src={Settings} alt="" />
              </span>
              <Link to={""}>Settings</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarNavigation;
