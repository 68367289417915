import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";
import {GETALLPOPTYPE, GETPOPLOCATION, GETPOPORGANISATION, GETPOPTYPESTATS} from "../actions/Types";

export default function POPManagementReducer(
  state = StoreState.popManagement,
  action
) {
  switch (action.type) {
    case ActionType.GETALLPOP:
      var s = cloneDeep(state);
      s.getAllPOPTypes = action?.data?.data;
      return s;
    case ActionType.GETPOPTYPES:
      var s1 = cloneDeep(state);
      s1.getPOPTypes = action?.data?.data;
      return s1;
    case ActionType.GETPOPREGIONS:
      var s2 = cloneDeep(state);
      s2.getPOPRegions = action?.data?.data;
      return s2;
    case ActionType.GETPOPZONES:
      var s3 = cloneDeep(state);
      s3.getPOPZones = action?.data?.data;
      return s3;
    case ActionType.GETPOPTERRITORIES:
      var s4 = cloneDeep(state);
      s4.getPOPTerritories = action?.data?.data;
      return s4;
    case ActionType.GETALLPOPTYPE:
      var s5 = cloneDeep(state);
      s5.popTypes = action?.data?.data;
      return s5;
    case ActionType.GETPOPTYPESTATS:
      var s6 = cloneDeep(state);
      s6.popTypeStats = action?.data?.data;
      return s6;
    case ActionType.GETPOPLOCATION:
      var s7 = cloneDeep(state);
      s7.location = action?.data?.data;
      return s7;
    case ActionType.GETPOPORGANISATION:
      var s8 = cloneDeep(state);
      s8.organisation = action?.data?.data;
      return s8;
    case ActionType.GETPOPREGIONSTATS:
      var s9 = cloneDeep(state);
      s9.regionStat = action?.data?.data;
      return s9;
    case ActionType.GETPOPTYPESBYPOPID:
      var s10 = cloneDeep(state);
      s10.popBypopId = action?.data?.data;
      return s10;
    default:
      return state;
  }
}
