import React from "react";
import PropTypes from "prop-types";

export const Select = ({
  className = "form-select",
  value = "",
  name = "",
  id = "",
  label = "",
  disabled = false,
  changeHandler,
  options = [],
  required = false,
  hidden,
  labelClass,
  ThemeColor,
  activity,
}) => {
  return (
    <div className="select_field">
      {label && !hidden ? (
        <div className="mb-1">
          <label
            className={`${
              activity
                ? "text-black text-xs paragraphs_font"
                : `text-xs paragraphs_font ${
                    labelClass
                      ? !ThemeColor
                        ? "text-white"
                        : "text-black"
                      : "text-white"
                  }`
            }`}
            htmlFor={name}
          >
            {label}{" "}
            {required ? <span className="text-red-500 ml-1">*</span> : ""}
          </label>
        </div>
      ) : (
        ""
      )}

      <select
        className={className}
        name={name}
        id={id}
        // defaultValue={value}
        value={value}
        onChange={(e) => changeHandler(e)}
        required={required}
        disabled={disabled}
        hidden={hidden}
        aria-label="select example"
      >
        {options.map((item, index) => {
          return (
            <option key={index} value={item.value || item.id}>
              {item.name} {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  changeHandler: PropTypes.func,
  options: PropTypes.array.isRequired,
};
