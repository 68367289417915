import React, { useEffect } from "react";
import moment from "moment";
import { Badge, Descriptions } from "antd";
export const DescriptiveTable = ({ data, TransformData, TransformValues }) => {
  const items = [
    {
      key: "1",
      label: "Activity Name",
      children: data?.form_name,
    },
    {
      key: "2",
      label: "Activity Type",
      children: TransformData[data?.activityTypeId],
    },
    {
      key: "3",
      label: "Organization",
      children: TransformValues("Organization"),
    },
    {
      key: "4",
      label: "Form Description",
      span: 3,
      children: data?.form_description,
    },
    {
      key: "5",
      label: "Category",
      children: data?.category_name,
    },
    {
      key: "6",
      label: "Sub Category",
      children: data?.sub_category_name,
    },
    {
      key: "7",
      label: "Product",
      children: data?.product_name,
    },
    {
      key: "8",
      label: "Status",
      span: 3,
      children: <Badge status="processing" text={data?.status} />,
    },
    {
      key: "7",
      label: "Start Date",
      children: moment(data?.formStartDate).format("DD-MM-YY"),
    },
    {
      key: "8",
      label: "End Date",
      children: moment(data?.formEndDate).format("DD-MM-YY"),
    },
    {
      key: "9",
      label: "Field Staff Group",
      children: TransformValues("staff"),
    },
  ];
  return (
    <>
      <p className="text-black text-xl pb-3">View Form</p>
      <Descriptions layout="vertical" bordered items={items} />
    </>
  );
};
