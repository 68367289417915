import React, { useEffect } from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";

export const DataTable = ({
  Columns,
  data,
  setSelectedRowKeys,
  selectedRowKeys,
  withOutCheckBox = false,
  handleSelectIds = () => {},
}) => {
  const ThemeColor = useSelector((state) => state?.common?.Theme);
  const dataWithKeys = data.map((item, index) => ({
    ...item,
    key: index.toString(),
  }));

  useEffect(() => {
    var tableElement = document.getElementsByClassName("ant-table-content")[0];
    var tableHeader = document.getElementsByClassName("ant-table-tbody")[0];
    var rows = document.getElementsByClassName("ant-table-cell");
    var icon = document.getElementsByClassName("anticon-filter");
    if (ThemeColor) {
      tableElement.classList.remove("table_bg_color_black");
      tableHeader.classList.remove("table_rows_black");
      for (var i = 0, len = rows.length; i < len; i++) {
        let sigleCell = rows[i];
        sigleCell.classList.remove("border-bottom");
      }
      for (var i = 0, len = icon.length; i < len; i++) {
        let sigleIcon = icon[i];
        sigleIcon.classList.remove("icon-white");
      }
    } else {
      tableElement.classList.add("table_bg_color_black");
      tableHeader.classList.add("table_rows_black");

      for (var i = 0, len = rows.length; i < len; i++) {
        let sigleCell = rows[i];
        sigleCell.classList.add("border-bottom");
      }
      for (var i = 0, len = icon.length; i < len; i++) {
        let sigleIcon = icon[i];
        sigleIcon.classList.add("icon-white");
      }
    }
  }, [ThemeColor]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    handleSelectIds(getRowIds(newSelectedRowKeys));
  };

  const getRowIds = (newSelectedRowKeys) => {
    return dataWithKeys
      .filter((item) => newSelectedRowKeys.includes(item.key))
      .map((item) => item.id);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <Table
        bordered={ThemeColor}
        rowSelection={withOutCheckBox ? !rowSelection : rowSelection}
        columns={Columns}
        dataSource={dataWithKeys}
      />
    </div>
  );
};
