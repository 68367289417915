import React, { useState } from "react";
import Header from "./Header";
import PropTypes from "prop-types";
import dashboard_bg from "../../assets/dashboard_bg.png"; 
import { useDispatch, useSelector } from "react-redux";
import { ChangeTheme } from "services/api/common";
import { useLocation } from 'react-router-dom';

export const DashboardLayout = ({ children }) => {
  const location = useLocation();
  const { pathname} = location;
  const dispatch = useDispatch();
  const ThemeColor = useSelector((state) => state?.common?.Theme);
  const [data, setData] = useState(true)
  const changeThemeColor = () => {
    setData(!data);
    dispatch(
      ChangeTheme(data)
    );
  };
  return (
    <>
      <div className="flex relative h-screen bg_color">
        <div>
        <img src={dashboard_bg} alt="background" className="fixed h-full right-0"/>
        <div className="absolute top-0 bottom-0 left-0 right-0 w-full bg-black opacity-20 z-20"></div>
        </div>
        <Header changeThemeColor={changeThemeColor} ThemeColor={!ThemeColor} pathname={pathname}/>
        <div>
          {/* <Sidebar /> */}
        </div>
        <div className="text-black mt-14 ml-14 z-30 w-11/12">{children}</div>
      </div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
